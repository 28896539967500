import React, { useState, useEffect } from "react";
//import PropTypes from "prop-types";
import { useHistory, useLocation, Link, matchPath } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccountIcon from '@material-ui/icons/AccountCircle';

import api from "../api";
import usePortal from './hooks/usePortal';
import uiConfig from '../uiConfig';
import config from '../config';
import { inject } from "mobx-react";
import Footer from "./Footer";
import { useFeedback } from './feedback/Service';
import Search from "./Search";

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    "& .MuiToolbar-root .MuiButton-root": {
      color: "#eee"
    },
    "& .MuiToolbar-root .MuiIconButton-root": {
      color: "#eee"
    },
  },
  menuButton: {
    //marginRight: theme.spacing(2),
  },
  title: {
    //flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  logo: {
    height: uiConfig.main.logoHeight,
    minHeight: uiConfig.main.logoHeight,
    padding: uiConfig.main.logoPadding,
    marginTop: uiConfig.main.logoMarginTop,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      height: uiConfig.main.logoHeightXs,
      minHeight: uiConfig.main.logoHeightXs,
    },
  },
  left: {
    marginRight: theme.spacing(2),
  },
  searchWrapper: {
    flexGrow: 1,
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  fetchingProgress: {
    position: 'fixed',
    top: uiConfig.main.progressBarTop,
    width: '100%',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      top: uiConfig.main.progressBarTopXs,
    },
  },
  children: {
    flex: '1 0 auto',
    marginTop: uiConfig.main.childrenMarginTop,
    [theme.breakpoints.down('sm')]: {
      marginTop: uiConfig.main.childrenMarginTopXs,
    },
  },
  buy: {
    marginLeft: theme.spacing(2),
  },
  icon: {
    fontSize: "2rem",
  }
}));

const Main = ({ children, isLoggedIn, store, isFetching, events, portal, user, unreadMessagesCount }) => {
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [logo, setLogo] = useState("");

  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const feedback = useFeedback();

  const years = config.portals.map(x => x.year).join('|');
  const yearPath = `/:year(${years})?`;
  const eventPath = `/event/:eventAlias`;

  const { params } = matchPath(pathname, {
    path: yearPath,
    exact: false,
    strict: false
  });
  const eventParams = matchPath(pathname, {
    path: eventPath,
    exact: false,
    strict: false
  });
  const eventAlias = eventParams?.params?.eventAlias;
  const event = events.find(x => x.alias === eventAlias);
  const showExhibitHall = !!event?.enableExhibitHall && !!event?.hasExhibitHallAccess;
  const { year: portalYear, portalName, urlPrefix, uiSettings } = usePortal(params.year);
  const isPortalPage = pathname.includes('on-demand');
  // const isExhibitHall = pathname.includes('exhibit');
  const handleLoginClick = () => {
    history.push((pathname === "/" ? "" : pathname) + '/login');
  }

  history.listen((location, action) => {
    if (action === "REPLACE") return;
    store.setSearch("");
  });
  const handleOpenDrawer = () => setShowDrawer(true);
  const handleCloseDrawer = () => setShowDrawer(false);

  useEffect(() => {
    store.setPortalName(portalName);
  }, [portalName]);

  useEffect(() => {
    const hasToken = localStorage.getItem(`${portalName}-access_token`);
    if (hasToken) {
      // console.log('requesting user data');
      api.getUser("me", null, portalName).then((resp) => {
        store.setUser(resp);
        setIsLoggingIn(false);
        store.setIsLoggingIn(false);
      }).catch(x => {
        console.warn("Login error", x);
        api.logOut(portalName);
        setIsLoggingIn(false);
        store.setIsLoggingIn(false);
      });
    } else {
      // console.log("no token");
      store.setIsLoggingIn(false);
      setIsLoggingIn(false);
    }
  }, [store, portalName]);

  useEffect(() => {
    if (logo)
      return;
    const loadLogo = async () => {
      const { default: url } = await import('../assets/images/' + uiSettings.logo);
      //console.log(url);
      setLogo(url);
    }
    loadLogo();
  }, [logo, uiSettings.logo]);

  useEffect(() => {
    store.fetchPortal().catch(err => {
      console.log(err);
      feedback.alert({ title: "Can't connect to API", text: "Possible causes: the network is offline, azurewebsites.net domain is blocked by your firewall or network administrator." })
    });
    const interval = setInterval(() => store.fetchPortal(), 30_000);
    return () => {
      clearInterval(interval);
    }
  }, [isLoggedIn]);

  if (isLoggingIn) return (<CircularProgress size={50} className={classes.progress} />);
  if (!portal) return (<CircularProgress size={50} className={classes.progress} />);

  const links = [
    ...config.portals.reverse().filter(x => x.year !== portalYear).map(({ year }) => {
      const url = year !== config.defaultYear ? "/" + year : "/";
      return { url, title: year, show: true, component: "a", href: url }
    }),
    { url: urlPrefix + "/on-demand", title: "On-Demand", show: (isLoggedIn || !uiConfig.requireAuth) && uiConfig.showOnDemand },
    { href: `//slidespiel.com/${event?.alias}/presentations`, component: "a", target: "_blank", rel: "noreferrer noopener", title: "My Session(s)", show: !!event && user?.isSpeaker },
    { url: urlPrefix + `/event/${event?.alias}`, title: "Home", show: !!event },
    { url: urlPrefix + `/event/${event?.alias}/live/session-evaluation`, title: "Session Evaluation", show: !!event && event.evaluationFormMetaData && pathname.includes("/live") && uiConfig.showSessionEvaluation },
    { url: urlPrefix + `/event/${event?.alias}/live`, title: "Live", show: isLoggedIn && event?.hasAccess },
    { url: pathname.replace(/\/+$/, "") + "/chat", title: "Chat", show: isLoggedIn && uiConfig.showChat && event?.hasExhibitHallAccess /* && isExhibitHall */, badge: unreadMessagesCount },
    { url: urlPrefix + `/event/${event?.alias}/exhibit-hall`, title: "Exhibit Hall", show: isLoggedIn && uiConfig.showExhibitHall && showExhibitHall },
    { url: urlPrefix + `/event/${event?.alias}/sponsors`, title: "Sponsors", show: isLoggedIn && !!event && uiConfig.showSponsorsPage },
    ...(event?.supplementaryPages || []).map(item => ({ url: urlPrefix + `/event/${event?.alias}/pages/${item.alias}`, title: item.name, show: item.isPublic || (isLoggedIn && event?.hasAccess) })),
    ...(portal?.supplementaryPages || []).map(item => ({ url: urlPrefix + `/pages/${item.alias}`, title: item.name, show: item.isPublic || isLoggedIn })),
    { url: pathname.replace(/\/+$/, "") + "/certificates", title: "Certificates", show: isLoggedIn && portal?.hasCertification },
    { url: pathname.replace(/\/+$/, "") + "/account", title: "Account", show: isLoggedIn, icon: AccountIcon },
  ].filter(x => x.show);
  return (
    <div className={classes.root}>
      <AppBar position="fixed" color={uiConfig.main.barColor} style={{ background: "#350279" }}>
        <Toolbar>
          <Hidden smUp>
            <IconButton edge="start" className={classes.menuButton} color={uiConfig.main.barTextColor} aria-label="menu" onClick={handleOpenDrawer}>
              {showDrawer ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>
          </Hidden>
          <Link to={urlPrefix || '/'}>
            <img src={logo} className={classes.logo} alt="Logo" />
          </Link>
          {/* <Hidden smDown>
            <Typography variant="h5" className={classes.title}>
              SEAOC Convention
            </Typography>
          </Hidden> */}
          <div className={classes.searchWrapper} style={{ visibility: isPortalPage ? 'visible' : 'hidden', }}>
            <Search />
          </div>
          <Hidden xsDown>
            {links.map(({ url, title, component = Link, href, target, rel, badge, icon: Icon }, index) => {
              const titleComponent = Icon ? <Icon className={classes.icon} /> : title;
              const ButtonComponent = Icon ? IconButton : Button;
              return (
                badge ?
                  <Badge badgeContent={badge} color="error" key={index} max={99} overlap="circle">
                    <ButtonComponent color={uiConfig.main.barTextColor} component={component} to={url} href={href} target={target} rel={rel}>{titleComponent}</ButtonComponent>
                  </Badge>
                  :
                  <ButtonComponent color={uiConfig.main.barTextColor} key={index} component={component} to={url} href={href} target={target} rel={rel}>{titleComponent}</ButtonComponent>
              )
            })}
          </Hidden>
          {!isLoggedIn && <Button color={uiConfig.main.barTextColor} onClick={handleLoginClick}>Sign In</Button>}
          {(uiConfig.showBuy && !isLoggedIn) && <Button className={classes.buy} color={uiConfig.main.barButtonColor} to={(pathname === "/" ? "" : pathname) + "/buy"} component={Link} variant="contained">Buy</Button>}
          {(uiConfig.showSignUp && !isLoggedIn) && <Button className={classes.buy} color={uiConfig.main.barButtonColor} to={(pathname === "/" ? "" : pathname) + "/sign-up"} component={Link} variant="contained">Sign Up</Button>}
        </Toolbar>
      </AppBar>
      {isFetching && <LinearProgress className={classes.fetchingProgress} variant="query" />}
      <Drawer open={showDrawer} onClose={handleCloseDrawer} anchor="bottom">
        <div
          className={classes.fullList}
          role="presentation"
        >
          <List>
            {links.map(({ url, title, component = Link, href, target, rel }, index) => (
              <ListItem button key={index} component={component} to={url} onClick={handleCloseDrawer} href={href} target={target} rel={rel}>
                <ListItemText primary={title} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      <div className={classes.children}>
        <Container>
          {children}
        </Container>
      </div>
      <Footer links={links} />
    </div>
  );
};

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn,
  isFetching: store.isFetching,
  events: store.events,
  portal: store.portal,
  user: store.currentUser,
  unreadMessagesCount: store.unreadMessagesCount,
  store
}))(Main);
