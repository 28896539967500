export const portals = [
  {
    year: 2022,
    home: {
      mainText: "Access Education from The Top Specialists!",
      secondaryText: "",
      list: [
        "Virtual Exhibit Hall",
        "24-hours access to the educational content",
        "Available on mobile devices"
      ],
      ctaText: "Sign Up",
      ctaTextLoggedIn: "Explore",
    }
  },
];
