import React, { useState, useEffect, useRef, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Message from './Message';
// import ChatConnection from "./ChatConnection";
import { makeStyles } from '@material-ui/core/styles';
import { inject, Observer } from 'mobx-react';
import Api from '../../api';
import { useFeedback } from '../feedback/Service';
import usePrevious from '../hooks/usePrevious';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 2),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#fff",
    height: "calc(100% - 10px)",
    // minHeight: 600,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      minHeight: 300,
      marginBottom: 50,
    }
  },
  title: {
    margin: 0,
    padding: theme.spacing(1, 0, 2),
    "& > span": {
      float: 'right',
    },
    fontSize: '1rem',
  },
  messages: {
    position: "absolute",
    scrollbarWidth: "thin",
    top: 37,
    left: 0,
    height: "calc(100% - 160px)",
    width: "calc(100% - 2px)",
    overflowY: "scroll",
    overflowX: "hidden",
    // minHeight: 400,
    ["&::-webkit-scrollbar"]: {
      width: "7px",
    },
    ["&::-webkit-scrollbar-track"]: {
      "box-shadow": "inset 0 0 3px rgba(0, 0, 0, 0.5)",
      "-webkit-box-shadow": "inset 0 0 3px rgba(0, 0, 0, 0.5)",
      "-webkit-border-radius": "2px",
      "border-radius": "2px",
    },
    ["&::-webkit-scrollbar-thumb"]: {
      "-webkit-border-radius": "3px",
      borderRadius: "3px",
      background: "#333",
      cursor: "pointer",
      "box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.5)",
      "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.5)",
    },
    ["&::-webkit-scrollbar-thumb:window-inactive"]: {
      background: "#333"
    }
  },
  controls: {
    position: "absolute",
    bottom: 80,
    left: 5,
    width: "calc(100% - 10px)",
    height: 0,
    minHeight: 40,
  },
  button: {
    float: 'right',
    margin: theme.spacing(1),
  },
  count: {
    float: "right",
  },
  online: {
    marginLeft: theme.spacing(1),
    color: 'green',
  }
}));

const UsersList = ({ data }) => {
  const classes = useStyles();
  let users = [...data];
  users = users.sort((a, b) => a.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1);
  return (
    <>
      {users.map(user => (
        <Typography key={user.user_id}>
          {user.full_name}
          {user.online && <Typography variant='caption' component="span" className={classes.online}>online</Typography>}
        </Typography>
      ))}
    </>
  )
}

const Chat = ({ show, oneWay, isStreamer = false, messages: initialMessages, connection, credentials }) => {
  // const [messages, setMessages] = useState([]);
  // const [connection, setConnection] = useState(null);
  const [recipientId, setRecipientId] = useState(null);
  const [menu, setMenu] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [isSending, setIsSending] = useState(false);
  const classes = useStyles();
  const messagesRef = useRef(null);
  const feedback = useFeedback();
  const presence = useMemo(() => connection.getUserPresence(recipientId), [recipientId]);

  useEffect(() => {
    if (!connection)
      return;
    const get = async () => {
      if (credentials.recipientId)
        setRecipientId(credentials.recipientId);
      if (credentials.recipient) {
        // console.log("credentials.recipient", credentials.recipient);
        const userId = await connection.getUserIdByEmail(credentials.recipient);
        setRecipientId(userId);
      }
    }
    get();
  }, [credentials.recipient, credentials.recipientId, connection]);

  // console.log("recipientId", recipientId);

  const messages = useMemo(() => {
    return initialMessages.filter(x => x.display_recipient.map(c => c.id).includes(recipientId));
  }, [initialMessages, recipientId]);

  // const addMessage = message => setMessages(oldMessages => {
  //   // if (message.receiverId !== credentials.group?.toLowerCase()) return;
  //   if (oldMessages.map(x => x.id).includes(message.id))
  //     return oldMessages;
  //   return [...oldMessages, message];
  // });

  const handleSendMessage = async e => {
    e.preventDefault();
    const messageText = inputValue;
    if (!messageText.trim())
      return;
    setIsSending(true);
    try {
      const message = await sendMessage(messageText);
      console.log('[CHAT] message sent:', message);
      setInputValue("");
    }
    catch (ex) {
      console.log('[CHAT] Error sending message:', ex);
    }
    setIsSending(false);
  }

  const sendMessage = async (messageText) => {
    let params;
    if (credentials.group) {
      params = { type: "stream", to: [credentials.group], content: messageText, topic: "general" };
    } else {
      // return console.error("[CHAT]", "can't send message", "user not found");
      params = { type: "private", to: [recipientId], content: messageText, topic: "general" };
    }
    const message = await connection.send(params);
    return message;
  }

  const handleFileChange = async e => {
    const file = e.target.files[0];
    e.target.value = null;
    if (!file)
      return;
    setIsSending(true);
    try {
      const urls = await Api.uploadChatFile(file);
      const url = urls?.length > 0 ? urls[0] : null;
      if (!url)
        throw new Error("file upload failed");
      const messageText = "::FILE" + JSON.stringify({ url, name: file.name });
      const message = await sendMessage(messageText);
      console.log("[CHAT] Media message sent successfully", message);
    }
    catch (ex) {
      console.log("[CHAT] Media message sending failed with error", ex);
    }
    setIsSending(false);
  }

  const handleJoinClick = () => { }

  const handleMenuOpen = menu => setMenu(menu)
  const handleMenuClose = () => setMenu({});

  const banUser = message => async () => {
    // const x = await connection.client.users.ban(message.sender_id);
    // console.log("[CHAT]", "ban", x);
    handleMenuClose();
    if (!await feedback.confirm({ title: `Ban ${message.sender_full_name}?` }))
      return;
    console.log("[CHAT]", "message to ban", message);
    try {
      const res = await connection.client.users.ban(message.sender_id);
      console.log("[CHAT]", "user banned", res);
      feedback.snackbar({ text: "User banned", type: "success" });
      // setMessages(x => x.filter(x => x.id !== message.id));
    } catch (error) {
      console.error("[CHAT]", "ban user error", error);
      feedback.snackbar({ text: error?.msg || "Ban user error", type: "error" });
    }
  }

  const deleteMessage = message => async () => {
    handleMenuClose();
    if (!await feedback.confirm({ title: "Delete the message?" }))
      return;
    console.log("[CHAT]", "message to delete", message);
    try {
      const res = await connection.client.messages.deleteById({ message_id: message.id });
      console.log("[CHAT]", "message deleted", res);
      feedback.snackbar({ text: "Message deleted", type: "success" });
      // setMessages(x => x.filter(x => x.id !== message.id));
    } catch (error) {
      console.error("[CHAT]", "delete message error", error);
      feedback.snackbar({ text: error?.msg || "Delete message error", type: "error" });
    }
  }

  const downloadLog = async () => {
    const text = messages.filter(x => x.content).map(x => `[${dayjs(x.timestamp * 1000).format('LT')}] ${x.sender_full_name}: ${x.content}`).join('\r\n');
    const filename = "chat_log_" + dayjs().format("YYYY-MM-DD_HH-mm") + ".txt";
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const handleUsersClick = () => {
    feedback.alert({ title: "Chat Room Users", component: <UsersList data={connection.groupUsers} />, clickAwayClose: true, scroll: 'paper', fullWidth: true });
  }

  const prevMessages = usePrevious(messages);

  useEffect(() => {
    const { current: ref } = messagesRef;
    if (!ref || !messages || !prevMessages)
      return;

    // console.log("[CHAT]", "scroll effect", Math.abs(ref.scrollHeight - ref.scrollTop - ref.offsetHeight));

    if (Math.abs(ref.scrollHeight - ref.scrollTop - ref.offsetHeight) < 150 || (prevMessages.length === 0 && messages.length > 0))
      ref.scrollTop = ref.scrollHeight;
  }, [messages, prevMessages]);

  if (!connection)
    return (
      <div className={classes.container} style={show ? undefined : { display: 'none' }}>
        <Typography>Initialization...</Typography>
        <LinearProgress variant="query" style={{ width: '100%' }} />
      </div>
    );

  // console.log("[CHAT]", "messages", messages.map(x => ({ ...x })));
  return (
    <Observer>
      {() => (
        <div className={classes.container} style={show ? undefined : { display: 'none' }}>
          {/* {error && <p><b>Login failed</b></p>} */}
          {connection.state === "Error" &&
            <Typography>Chat Error</Typography>
          }
          {connection.state === "Connecting" &&
            <>
              <Typography>Connecting...</Typography>
              <LinearProgress variant="query" style={{ minHeight: 0, width: '100%' }} />
            </>
          }
          {connection.state === "Connected" &&
            <>
              <Typography variant="h6" className={classes.title}>
                {connection.getUserName(recipientId)} <Typography variant='caption' component="b">{presence}</Typography>
                {/* {showLogoff && <Button variant="outlined" size="small" onClick={this.handleLogoutClick}>Log Out</Button>} */}
                {connection.roomID && <Typography variant="caption" component={Button} className={classes.count} onClick={handleUsersClick}>Users Online: {connection.onlineCount}/{connection.usersCount}</Typography>}
              </Typography>
              <div className={classes.messages} ref={messagesRef}>
                {messages.map(message => (
                  <Message
                    oneWay={oneWay}
                    handleMenuOpen={handleMenuOpen}
                    data={message}
                    // isAdmin={connection.isAdmin}
                    key={message.id}
                    // userId={connection.profile.user_id}
                    connection={connection}
                  />
                ))}
              </div>
            </>
          }
          <Menu
            id="message-menu"
            anchorEl={menu.anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
            transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            keepMounted
            open={!!menu.anchorEl}
            onClose={handleMenuClose}
          >
            {menu.showDelete && <MenuItem onClick={deleteMessage(menu.data)}>Delete Message</MenuItem>}
            {menu.showBan && <MenuItem onClick={banUser(menu.data)}>Ban User</MenuItem>}
          </Menu>
          {connection.state === "Connected" &&
            <div className={classes.controls}>
              {!connection.isBanned ?
                <>
                  {connection.profile.name !== "reader" &&
                    <form id="chat-message-text" onSubmit={handleSendMessage}>
                      <input
                        type="text"
                        className="input-field"
                        name="message"
                        value={inputValue}
                        disabled={isSending}
                        onChange={({ target: { value } }) => setInputValue(value)}
                        placeholder="Message"
                        autoComplete="off"
                      // onKeyPress={this.handleInputKeyPress}
                      />
                    </form>
                  }
                  {isSending && <LinearProgress variant="indeterminate" style={{ minHeight: 0, width: '100%' }} />}
                  <div>
                    <Button
                      type="submit"
                      onClick={connection.profile.name !== "reader" ? undefined : handleJoinClick}
                      form={connection.profile.name !== "reader" ? "chat-message-text" : undefined}
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      disabled={isSending}
                    >
                      {connection.profile.name !== "reader" ? "Send" : "Join"}
                    </Button>
                    {connection.isAdmin &&
                      <>
                        <input type="file" id="upload-file" style={{ display: 'none' }} onChange={handleFileChange} />
                        <label htmlFor="upload-file">
                          <Button
                            color="default"
                            style={{ float: isStreamer ? 'right' : 'left' }}
                            variant="contained"
                            component="span"
                            disabled={isSending}
                            className={classes.button}
                          >
                            Upload
                          </Button>
                        </label>
                        <Button onClick={downloadLog} className={classes.button}>Download log</Button>
                      </>
                    }
                  </div>
                </> :
                <Typography align='center' color="error">You are not permitted to post messages.</Typography>
              }
            </div>
          }
        </div>
      )}
    </Observer>
  );
}

export default inject(({ store }, props) => ({
  connection: store.chatConnection, messages: store.chatMessages, ...props
}))(Chat);